// reloadJs("/assets/build/test.js")

function reloadJs(src) {
	document.querySelector('script[src^="' + src + '"]').remove();
	var script = document.createElement("script");
	script.src = src + "?v=" + (new Date()).getTime()
	document.body.appendChild(script);
}

function getFileStats() {
	return new Promise((resolve, reject) => {
		fetch('/modified.php').then(res => {
			return res.json();
		}).then(date => {
			resolve(date)
		}).catch(err => {
			reject(err);
		});
	});
}

function loadJson(url) {
	return new Promise((resolve, reject) => {
		fetch(url).then(res => {
			return res.json();
		}).then(data => {
			resolve(data)
		}).catch(err => {
			reject(err);
		});
	});
}

loadJson('/includes/news.php').then(news => {
	console.log(news["news"]);
	console.log(news["modals"]);

	var target = document.getElementById("news");
	target.innerHTML = news["news"];
	document.body.insertAdjacentHTML('beforeend', news["modals"]);
});

loadJson('/includes/resources.php').then(resources => {
	Object.keys(resources).forEach(category => {
		var target = document.getElementById(category);
		target.innerHTML = resources[category];
	});
});

getFileStats().then(stats => {
	// How many minutes between checking for updates?
	var interval = 15,
		files = stats.files;
	
	localStorage.setItem("files", JSON.stringify(files));
	
	console.log("Interval is " + interval + " minutes");
	console.log(files);
	
	setInterval(() => {
		if (! document.body.classList.contains("modal-open")) {
			getFileStats().then(newjson => {
				files = JSON.parse(localStorage.getItem("files"));
				
				var newfiles = newjson.files;
				
				for (var filename in newfiles) {
					if (filename == "/index.php") {
						console.log(filename + ": " + files[filename]);
					}
					
					var newdate = newfiles[filename],
						date = files[filename];
					
					if (newdate !== date) {
						localStorage.setItem("files", JSON.stringify(newfiles));
						
						console.log(filename + " date has changed");
						
						switch(filename) {
							case "/index.php":
								location.reload();
							break;
							case "/pages/index.php":
								location.reload();
							break;
							case "/data/news.json":
								loadJson('/includes/news.php').then(news => {
									var target = document.getElementById("news");
									target.innerHTML = news["news"];
								});
							break;
							case "/data/resources.json":
								loadJson('/includes/resources.php').then(resources => {
									Object.keys(resources).forEach(category => {
										var target = document.getElementById(category);
										target.innerHTML = resources[category];
									});
								});
							break;
						}
					}
				}
			}).catch(err => {
				console.log(err);
			});
		}
	}, interval * 60000);

	// 300000 = 5 minutes
	// 900000 = 15 minutes
	// 1800000 = 30 minutes
	// 3600000 = 1 hour
}).catch(err => {
	console.log(err);
});
